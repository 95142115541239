import React, { useEffect, useState } from "react";
import Calendar from "./Calendar";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import BarChartIcon from "@mui/icons-material/BarChart";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import UserStats from "./UserStats";
import { Typography, Button } from "@mui/material";

/* eslint-disable react/prop-types */
export default function AnalyticsModal(props) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [globalStats, setGlobalStats] = useState({});
  const [iconAlignment, setIconAlignment] = useState("");
  const [displayIcon, setDisplayIcon] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setMessage(props.message);
  }, [props.message]);

  useEffect(() => {
    setDisplayIcon(props.displayIcon);
  }, [props.displayIcon]);

  useEffect(() => {
    if (props.iconAlignment) {
      setIconAlignment(props.iconAlignment);
    } else {
      setIconAlignment("left");
    }
  }, [props.iconAlignment]);

  useEffect(() => {
    setGlobalStats(JSON.parse(localStorage.getItem("global_stats")));
  }, [sessionStorage.getItem("global_stats")]);

  useEffect(() => {
    const storageEventHandler = (event) => {
      if (event.key === "global_stats") {
        setGlobalStats(JSON.parse(localStorage.getItem("global_stats")));
      }
    };

    window.addEventListener("storage", storageEventHandler);
    return () => {
      window.removeEventListener("storage", storageEventHandler);
    };
  }, []);

  const analyticsModalBody = (
    <>
      <Box
        sx={{
          overflow: "auto",
          position: "absolute",
          margin: "auto",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: { xs: "100dvh", md: "min(850px, 80dvh)" },
          width: "100vw",
          maxHeight: { xs: "100%", md: "850px" },
          maxWidth: { xs: "100%", md: "950px" },
          bgcolor: "background.paper",
          boxShadow: 24,
          paddingRight: 3,
          paddingLeft: 3,
          borderRadius: { xs: "0", md: "20px" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop="2dvh"
          marginBottom="1dvh"
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ color: "black" }}
          >
            <CloseIcon></CloseIcon>
          </IconButton>
        </Box>
        <Box
          marginBottom="2dvh"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box width="300px">
            <UserStats
              globalCount={globalStats ? globalStats.count : 0}
              inModal={true}
            ></UserStats>
          </Box>
        </Box>
        <Box
          sx={{
            borderBottom: "thin solid #A9A9A9",
            width: "100%",
          }}
        ></Box>
        <Box marginTop="3dvh">
          <Calendar></Calendar>
        </Box>
      </Box>
    </>
  );

  const buttonContent = (
    <>
      {iconAlignment === "left" && <BarChartIcon />}
      {message && (
        <Typography
          paddingLeft={1}
          paddingRight={1}
          width="inherit"
          textAlign={() => {
            if (iconAlignment === "left") {
              return "left";
            }
            return "center";
          }}
          fontSize="inherit"
        >
          {message}
        </Typography>
      )}
      {iconAlignment === "right" && <BarChartIcon />}
    </>
  );

  return (
    <>
      {displayIcon && (
        <IconButton
          size="medium"
          color="inherit"
          aria-label="analytics"
          onClick={handleOpen}
          sx={{
            borderRadius: "inherit",
            width: "inherit",
            fontSize: "inherit",
            paddingRight: { xs: "5px", md: "10px" },
            paddingLeft: { xs: "5px", md: "10px" },
          }}
        >
          {buttonContent}
        </IconButton>
      )}
      {!displayIcon && (
        <Button
          size="large"
          variant="contained"
          aria-label="analytics"
          onClick={handleOpen}
          sx={{
            borderRadius: "10px",
            width: "inherit",
            fontSize: "inherit",
          }}
        >
          {buttonContent}
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="analytics-modal-title"
        aria-describedby="analytics-modal-description"
      >
        {analyticsModalBody}
      </Modal>
    </>
  );
}
